let English = {
    translations: {
        "SECURELY_STAKE": "Securely Stake Your Assets",
        "RETURNS":  "Returns",
        "EARN": "& Earn up to",
        "STAKENOW": "STAKE NOW",
        "LEARNMORE":"LEARN MORE",
        "UNRIVALLED_ENTERPRISE": "Providing enterprise-grade blockchain infrastructure for proof-of-stake networks to accelerate the adoption of decentralised technology. ",
        "UNIQUEUSERS": "Unique Users",
        "UNIQUE_DELEGATORS": "Unique Delegators",
        "ASSETSSTAKED":"Assets Securely Staked",
        "NETWORKS_SECURED": "Networks Secured",
        "GUARANTEE_UPTIME": "Guaranteed Uptime",
        "HOWITWORKS": "How it Works",
        "STEP_ONE": "Step 1",
        "STEP_ONE_DES":"Choose the token you want to stake with AUDIT.one",
        "STEP_TWO": "Step 2",
        "STEP_TWO_DES":"Use our staking guides and delegate to the AUDIT.one validator",
        "STEP_THREE": "Step 3",
        "STEP_THREE_DES": "Claim returns and re-stake regularly for compounding effects",
        "WHY_PEOPLE_TRUST":"Why People Trust Us",
        "DECEN_INFRA": "Decentralised Infrastructure",
        "DECEN_INFRA_DES":"Our infrastructure consists of both bare-metal and cloud-based solutions and is spread across multiple data centers and providers.",
        "STATE_OF_ART":"State-of-the-Art Security",
        "STATE_OF_ART_DES":"We settle for nothing less than Tier 3 Data centers, and use distributed sentry nodes and multi-cloud redundancies to guarantee industry leading SLA's. ",
        "NON_CUSTODIAL_STAKING":"Non-Custodial Staking",
        "NON_CUSTODIAL_STAKING_DES": "When staking via an AUDIT.one validator, you keep full ownership of your funds at all time",
        "MONITERED_GLOBAL":"Monitored by Global Experts",
        "MONITERED_GLOBAL_DES":"Our team is set up globally to ensure around the clock availability. Automated monitoring systems ensure we never drop the ball.",
        "SUPPORTED_NTWS": "Supported Networks ",
        "SUPPORTED_NTWS_DES": "We want to provide all our users with a seamless experience through our platform, and are therefore working hard to integrate as many networks as possible. Our platform currently provides a direct staking solution for 6 networks. To stake on any of the other 20 networks, we kindly refer to our staking guides.",
        "AVAILABLE_NTWS":"Available Networks",
        "LAUNCH_PLATFORM": "LAUNCH PLATFORM",
        "UPCOMING_STAKING": "Upcoming Staking Options",
        "AUDIT_AMBASSADOR": "Do You Want to join AUDIT.one?",
        "AUDIT_DES_ONE": "We are looking for passionate individuals to join us on our mission to accelerate the adoption of decentralized technology.",
        "AUDIT_DES_TWO": "Are you someone who knows infrastructure, nodes, monitoring, cloud and much more? Are you intrinsically motivated to architect solutions for the networks we secure? If yes, consider joining AUDIT.one.",
        "JOIN_NOW": "JOIN NOW",
        "ASSEEN_ON": "As Seen On",
        "NETWORKS": "Networks",
        "FOLLOW_US":"Follow Us On Social Media",
        "PERSISTENCE": "Persistence",
        "COSMOS": "Cosmos",
        "KAVA": "Kava",
        "SOLANA":"Solana",
        "AKASH": "Akash",
        "SENTINEL": "Sentinel",
        "GET_IN_TOUCH": "Get In Touch",
        "HELLO_AUDIT":"hello@audit.one",
        "SUBMIT_QUERY":"Submit a Query",
        "DOCUMENTATION":"DOCUMENTATION",
        "CONTACT_US":"CONTACT US",
        "STAKE_NOW": "Stake Now",
        "VIEW_MORE": "VIEW MORE",
        "STAKING_GUIDE": " Stake Using Staking Guide",
        
        "WHY_TRUST_US": "Why Trust Us?",
        "DECENTRALIZED_INFRASTRUCTURE": "Decentralized Infrastructure",
        "DECENTRALIZED_INFRASTRUCTURE_DESCRIPTION": "As true believers in decentralization, we have a Decentralized Infrastructure with highly redundant data centers across multiple geographical locations.",
        "STATE_OF_SECURITY": "State-of-the-art security",
        
        "STATE_OF_SECURITY_DESCRIPTION": "Multiple sentry nodes distributed across various cloud providers to mitigate risks of DOS attacks. Our Data Centers are built to meet Tier 3 standards.",
        "ONLINE_24": "24*7 Online",
        "ONLINE_24_DESCRIPTION": "Our decentralized infrastructure combined with a team of security experts help us achieve 24*7 operations for our Validator services. ",
        "LIVE": "Live",
        "UPCOMING": "Upcoming",
        "HEAR_FROM": "We want to hear from you and we will certainly respond!",
        "READ_LATEST_REPORT": " Read our latest report on the Near Protocol ecosystem and staking",
        "TRUST_US": "TRUST US",
        "NETWORKS_SUB": "NETWORKS",
        "BANNER_TEXT":"Visit the new AUDIT.ONE WIKI for Staking Guides, FAQs and other useful information",
        "WHY":"WHY",
        "CONTACT":"CONTACT",
        
    }
};

export default English;
