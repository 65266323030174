import React, { Component } from 'react';
import { Route, Switch, BrowserRouter as Router  } from 'react-router-dom';
import './css/style.css';
import './App.css';
import AOS from 'aos';
import 'aos/dist/aos.css';



const homepage = React.lazy(() => import('./webpages/Homepage'));

class App extends Component {
  componentDidMount()
  {
    
    AOS.init({
      duration : 2000
    })
   
  }
  render() {

    return (
      <React.Fragment>

        <Router>
        <React.Suspense fallback={<div>&nbsp;</div>}>
          <Switch>
              <Route path="/" component={homepage} /> 

           </Switch>
           </React.Suspense>
        </Router>        
      </React.Fragment>
    );
  }
}

export default App;
